<template>
  <div class="page">
    <label for="input">Iframe source</label>
    <input ref="input" id="input" type="text" placeholder="Iframe Source"/>
    <button @click="handleClick">Submit</button>
  </div>
</template>

<script>
export default {
  name: 'SearchView',
  methods: {
    handleClick () {
      this.$emit('navigate', this.$refs.input.value)
    }
  }
}
</script>

<style scoped>
.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: aliceblue;
}

.page > * {
  margin-bottom: 20px;
}

.page button
{
  height: 50px;
  padding: 0px 25px;
  background: green;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.page input
{
  height: 50px;
  border: none;
  padding: 0px 25px;
}
</style>
