<template>
  <div id="app">
    <SearchView v-if="!showIframe" @navigate="updatePage"></SearchView>
    <iframe ref="iframe"></iframe>
  </div>
</template>

<script>
import SearchView from './views/SearchView.vue'

export default {
  name: 'App',
  components: {
    SearchView
  },
  data () {
    return {
      target: "",
      showIframe: false
    }
  },
  methods: {
    updatePage (target) {
      this.target = target
      this.$refs.iframe.src = target
      this.showIframe = true
    }
  }
}
</script>

<style>
*
{
  margin: 0px;
  padding: 0px;
}

#app
{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#app iframe {
  border: none;
  height: 100vh;
  width: 100vw;
}

</style>
